<!-- eslint-disable semi -->
<template>
  <div class="p-6 bg-white">
    <div class="text-center">
      <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
        <span class="block xl:inline">Our </span>
        <span class="block text-custom-green xl:inline">Services</span>
      </h1>
      <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">Connect with talent that gets you, and hire them to take your business to the next level.</p>
      <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div class="flex flex-wrap justify-center mt-8 gap-4">
            <a
              href="/register"
              class="w-full sm:w-auto justify-center inline-flex items-center px-8 py-3 text-white bg-custom-green-dark border border-custom-green-dark rounded hover:bg-transparent hover:text-custom-green-dark active:text-indigo-500 focus:outline-none focus:ring"
            >
              <span class="text-base font-medium"> Get Started </span>
              <svg
                class="w-5 h-5 ml-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </a>
        </div>

      </div>
    </div>
<!-- Services  -->
<div class="max-w-7xl rounded mx-auto py-16 px-4 sm:px-6 lg:py-24 service-list lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8" v-for="(service, index) in services" :key="index">
  <div>
    <p class="mt-2 text-3xl font-extrabold text-gray-900">{{service.title}}</p>
    <p class="mt-4 text-lg text-gray-500">{{service.subTitle}}</p>
  </div>
  <div class="mt-12 lg:mt-0 lg:col-span-2">
    <div v-html="service.content" class="text-base leading-loose mb-4"></div>
    <dl class="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-4">
      <div class="relative" v-for="(item, key) in service.subCategories" :key="key">
        <dt>
          <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          <p class="ml-9 text-lg leading-6 font-medium text-gray-900">{{item}}</p>
        </dt>
      </div>
    </dl>
  </div>
</div>

<!-- /Services  -->
  </div>
</template>
<script setup>

// eslint-disable-next-line semi
import { reactive } from 'vue';

const services = reactive([
  {
    title: 'Transitional Care',
    subTitle: 'A more connected, personalized transitional care for your loved one',
    content: `<p>Need help with the safe transfer of a loved one from one healthcare setting to another or home? Or want someone to take care of them when you’re not around? Our transitional care helpers will assist you through the process.</p>
  <p>From picking up medicine and discharge assistance to meal preparation and assistance with movement, bathing, dressing, toileting, and more, we can help you with it all.</p>
    <p>No matter whether you need one-day help, a few weeks of post-hospitalization care, or assistance with ongoing therapy, our transitional services are customized to your needs.</p>`,
    subCategories: ['Transportation from the hospital', 'Prescription pick up', 'Discharge assistance', 'Mobility assistance']
  },

  {
    title: 'Handyman',
    subTitle: 'A  better home with all repairs fixed',
    content: `<p>Hire an efficient handyman with Husky Task and get rid of unwanted stress and delayed updates that your home needs.</p>
  <p>From minor home repairs to handling difficult maintenance, our experienced handymen can help get all work done around your home — and that too without costing you the earth.</p>
    <p>Whether you need help with carpentry, deck maintenance, fixing faulty wiring, plumbing, or flooring installation, you can find a reliable professional for all your home improvement needs.</p>`,
    subCategories: ['Handyman']
  },
  {
    title: 'Moving',
    subTitle: 'Enjoy a seamless, stress-free moving experience',
    content: `<p>Husky Task has made the moving simple — whether you’re moving across the country or the next street, our experienced movers make the process seamless for you. They are insured and properly trained to transport household goods without damage.</p>
  <p>By combining years of industry experience with the latest moving technology, our expert movers safely handle and carry your goods from your old location to the new one. Our movers can also help you with packing, unpacking, storage, removal of packing materials, and more.</p><p>Need help with emergency moving? Hire an emergency mover now!</p>`,
    subCategories: ['Moving']
  },
  {
    title: 'General Cleaning',
    subTitle: 'Relax in a clean home every day!',
    content: `<p>Our experienced and professional cleaners make sure that your home is thoroughly cleaned without needing you to break the bank.
        They know how to handle your delicate décor and furniture, and will clean it all from kitchen to bathroom and bedrooms.</p>
        <p>Get your surfaces wiped down, floors mopped, sofas vacuumed, dishes washed, bin emptied, toilets cleaned, windows and mirrors shinned, and faucets polished.</p>
        <p>You can hire our cleaners for daily, weekly, bi-weekly, monthly, or occasional cleaning.</p>
        <p>We also offer deep cleaning and clutter management services.</p>
        <p>Need help with additional cleaning services or want to customize your cleaning? Our taskers will accommodate your needs. Just let us know!</p>`,
    subCategories: ['General Cleaning']
  },
  {
    title: 'Cooking and Catering ',
    subTitle: 'Catering services customized to your needs and your nutritional goals',
    content: `<p>Don’t have time to cook and looking for a reliable private home chef? Or in need of a caterer for your family event?</p>
  <p>No matter if you are looking for an experienced private home chef or a caterer for an intimate dinner or a large event, you can find professional chefs for every kind of event at Husky Task.</p>
    <p>Our chefs are actually chefs — they will prepare wholesome, delicious food for you paired with great customer service.  They are experienced in handling catering needs for small parties and large events without compromising on the food quality or quantity.</p>`,
    subCategories: ['Cooking and Catering']
  },
  {
    title: 'Event Planning/Managing',
    subTitle: 'Be ready to host an unforgettable event',
    content: `<p>Husky Task has event planning and management professionals who will create an exciting event and memorable experience for your guests.</p>
  <p>No matter if you want to celebrate a stunning employee engagement event, host a meaningful corporate event, or effectively showcase your booth at a trade show, our event planners can do it all within your budget.</p>
    <p>From event consultation and design to event marketing and managing catering, audio-visuals, security, and seating, we are here to take care of everything so you can focus on more important things.</p>`,
    subCategories: ['Event Planning/Managing']
  },
  {
    title: 'Assembly/Disassembly',
    subTitle: 'Get your furniture assembled and disassembled without costly damages or mistakes',
    content: `<p>A pending move? Having trouble fitting the furniture through doorways? Let our professionals help you with furniture assembly and disassembly.</p>
  <p>With years of industry experience and appropriate training, our professionals know how to safely disassemble and assemble various pieces of furniture.</p>
    <p>Whether you need help with assembling or disassembling shelves, cabinets, desks, sofas, beds, and other items, our furniture assembly technicians have the right tools and skills to do the job without stripping out screws or causing cracks.</p>`,
    subCategories: ['Assembly/Disassembly']
  },
  {
    title: 'Grocery Shopping & Delivery',
    subTitle: 'Instant grocery shopping and delivery you can count on',
    content: `<p>Too busy to do groceries? Tired of long lines or crowded parking lots? Our helpers are here to take care of all your grocery needs, from shopping to picking and delivery.</p>
  <p>Hire a personal shopper who will shop and pick up your desired items with care and deliver them to your doorstep. You can enjoy instant delivery, same-day delivery, or schedule a convenient time for you.</p>`,
    subCategories: ['Grocery Shopping & Delivery']
  }
])
</script>
<style scoped>
.service-list:nth-child(odd){
  @apply bg-custom-green-dark bg-opacity-20;
}
</style>
